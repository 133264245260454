import theme from "@elegantstack/flow-ui-theme/src/theme/index"
//Using tailwind preset colors
import colorPreset from "@elegantstack/flow-ui-theme/src/theme/color-preset"
export default {
  ...theme,
  colors: {
    ...theme.colors,
    alphaLighter: colorPreset.purple[600],
    alphaLight: colorPreset.purple[300],
    alpha: colorPreset.purple[600],
    alphaDark: colorPreset.purple[800],
    alphaDarker: colorPreset.purple[900],
    heading: colorPreset.gray[800],
  },
}