import React from 'react'
import { css } from 'theme-ui'
import { Link } from 'theme-ui'
import { FaLink } from 'react-icons/fa'

const styles = {
  heading: {
    svg: {
      visibility: `hidden`,
      verticalAlign: `middle`,
      size: `icon.xs`,
      right: 0,
      pl: 2
    },
    ':hover svg': {
      visibility: `visible`
    },
    pointerEvents: `painted`
  }
}

const heading = Tag => props => (
  <Tag {...props} css={css(styles.heading)}>
    <Link href={`#${props.id}`} variant={`text.${Tag}`}>
      {props.children}
      <FaLink />
    </Link>
  </Tag>
)

export default {
  h1: heading(`h1`),
  h2: heading(`h2`),
  h3: heading(`h3`)
}
